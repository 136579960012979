import { z } from 'zod';

import { percentageNumberSchema, timestampSchema, uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { orgSimpleListingSchema } from './orgs';

export const fundManagerSchema = z.object({
  id: uuidSchema,
  walletAddress: addressSchema,
});
export type FundManager = z.infer<typeof fundManagerSchema>;

export const fundPiiSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});
export type FundPii = z.infer<typeof fundPiiSchema>;

export const fundCollaboratorSchema = fundPiiSchema.extend({
  id: uuidSchema,
  email: z.string().email(),
});
export type FundCollaborator = z.infer<typeof fundCollaboratorSchema>;

export const fundTypeSchema = z.enum(['Private', 'Community', 'Transparent', 'ImpactPool']);
export type FundType = z.infer<typeof fundTypeSchema>;

export const fundListingSchema = z.object({
  id: uuidSchema,
  chainId: z.number(),
  name: z.string(),
  type: fundTypeSchema,
  manager: fundManagerSchema,
  deploymentTransactionHash: addressSchema.nullish(),
  v2ContractAddress: addressSchema.nullish(),
  /** @deprecated */
  v1ContractAddress: addressSchema.nullish(),
  // TODO: Update for `fundPii` once coordinated with BE
  advisor: fundPiiSchema,
  featuredIndex: z.number(),
  lastBalanceSyncUTC: z.date().nullish(),
  description: z.string(),
  shortDescription: z.string().nullish(),
  vanityUrl: z.string().nullish(),
  logo: z.string().nullish(),
  banner: z.string().nullish(),
  createdAtUtc: timestampSchema,
  updatedAtUtc: timestampSchema,
  staffNotes: z.string().nullish(),
  category: z.string().nullish(),
  grantsGiven: z.number(),
  /** USDC value */
  usdcBalance: bigIntSchema.describe('Is represented as the Grantable Balance in the UI'),
  /** USDC value */
  totalGrantedUsdc: bigIntSchema.describe('Is represented as the Total Granted Amount in the UI'),
  /** USDC value */
  lifetimeDonationsUsdc: bigIntSchema.describe('Is represented as the Lifetime Donations in the UI'),
  /** USDC value */
  investedUsdc: bigIntSchema.describe('Is represented as either Invested or Allocated in the UI'),
  /** A USDC value */
  inTransitBuyUsdcAmount: bigIntSchema,
  /** A USDC value */
  inTransitSellUsdcAmount: bigIntSchema,

  // Visibility properties don't actually exist on the model, but are used to determine which fields to show in the UI
  positionsVisible: z.boolean({ coerce: true }),
  activityVisible: z.boolean({ coerce: true }),
  granteeVisible: z.boolean({ coerce: true }),
});
export type FundListing = z.infer<typeof fundListingSchema>;

const expectedDeploymentInfoSchema = z.object({
  expectedManagerAddress: addressSchema,
  expectedSalt: addressSchema,
  expectedComputedAddress: addressSchema,
});
const genericDetailsSchema = z.object({
  type: fundTypeSchema.exclude([fundTypeSchema.enum.ImpactPool]),
  poolDetails: z.null(),
  expectedDeploymentInfo: expectedDeploymentInfoSchema.nullish(),
});
const impactDetailsSchema = z.object(
  {
    type: z.literal(fundTypeSchema.enum.ImpactPool),
    poolDetails: z.object({
      eligibleEntities: z.enum(['Organizations']),
      algorithmType: z.enum(['QuadraticFunding']),
      distributionSchedule: z.string(),
      distributionPercentage: z.number().describe('A percentage number between 0 and 1'),
      eligibleVotes: z.enum(['GrantsAndDonations']),
      voteWeight: z.enum(['UsdcValue']),
    }),
    expectedDeploymentInfo: expectedDeploymentInfoSchema.nullish(),
  },
  {
    errorMap: () => ({
      message: 'Impact Pool details are required for Impact Pools',
    }),
  },
);

export const fundDetailsSchema = z.union([
  fundListingSchema.merge(genericDetailsSchema),
  fundListingSchema.merge(impactDetailsSchema),
]);
export type FundDetails = z.infer<typeof fundDetailsSchema>;

export const adminFundSchema = fundListingSchema.pick({
  id: true,
  name: true,
  type: true,
  manager: true,
  v2ContractAddress: true,
  advisor: true,
  featuredIndex: true,
  usdcBalance: true,
  createdAtUtc: true,
  chainId: true,
});
export type AdminFund = z.infer<typeof adminFundSchema>;

export const fundDistributionRoundSchema = z.object({
  id: uuidSchema,
  name: z.string(),
  roundStartTimestampUtc: timestampSchema.describe('Timestamp of round start'),
  roundEndTimestampUtc: timestampSchema.describe('Timestamp of round end'),
  distributedTimestampUtc: timestampSchema.nullish().describe('Timestamp of distribution processing'),
});
export type FundDistributionRound = z.infer<typeof fundDistributionRoundSchema>;

export const fundDistributionRoundSummarySchema = z.object({
  roundStartTimestampUtc: timestampSchema.describe('Timestamp of round start'),
  roundEndTimestampUtc: timestampSchema.describe('Timestamp of round end'),
  distributedTimestampUtc: timestampSchema.nullish().describe('Timestamp of distribution processing'),
  totalDistributionUsdc: bigIntSchema,
  impactPoolNav: bigIntSchema,
  distributionPercentage: percentageNumberSchema,
  topMajorCategories: z.array(
    z.object({
      nteeCode: z.string(),
      amount: bigIntSchema,
    }),
  ),
  recipientCount: z.number(),
});
export type FundDistributionRoundSummary = z.infer<typeof fundDistributionRoundSummarySchema>;

export const fundDistributionRecipientSchema = orgSimpleListingSchema.and(
  z.object({
    distributionAmount: bigIntSchema,
  }),
);
export type FundDistributionRecipient = z.infer<typeof fundDistributionRecipientSchema>;
