import { z } from 'zod';

import { COUNTRY_ISO3166_ALPHA3, COUNTRY_ISO3166_ALPHA3_NOT_US, US_STATES } from '@endaoment-frontend/constants';

import { einSchema, uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { physicalAddressSchema } from './user';

const orgContactSchema = z.object({
  email: z.string().nullish(),
  name: z.string().nullish(),
  phone: z.string().nullish(),
  title: z.string().nullish(),
});
const orgFinancialsSchema = z.object({
  form990FiscalYear: z.number().nullish(),
  summary: z.object({
    /** Dollar value */
    totalLiabilities: z.string().nullish(),
    /** Dollar value */
    totalAssets: z.string().nullish(),
    /** Dollar value */
    contributionsAll: z.string().nullish(),
    /** Dollar value */
    totalExpenses: z.string().nullish(),
    /** Dollar value */
    totalRevenue: z.string().nullish(),
    /** Dollar value */
    netIncome: z.string().nullish(),
  }),
  expenses: z.object({
    /** Dollar value */
    programServices: z.string().nullish(),
    /** Dollar value */
    administration: z.string().nullish(),
    /** Dollar value */
    fundraising: z.string().nullish(),
    /** Dollar value */
    accounting: z.string().nullish(),
  }),
});
const orgSocialsSchema = z.object({
  twitter: z.string().nullish(),
  facebook: z.string().nullish(),
  linkedin: z.string().nullish(),
  instagram: z.string().nullish(),
});
const orgDeploymentSchema = z.object({
  chainId: z.number(),
  contractAddress: addressSchema,
  usdcBalance: bigIntSchema.nullish(),
});
export type OrgDeployment = z.infer<typeof orgDeploymentSchema>;

/**
 * Should be the same as an optional `PhysicalAddress` but with country required
 * @see physicalAddressSchema
 */
export const minimalPhysicalAddressForOrgSchema = z
  .object({
    line1: z.string().nullish(),
    city: z.string().nullish(),
  })
  .and(
    z.union([
      z.object({
        state: z.preprocess(v => {
          if (v === '') return null;
          return v;
        }, US_STATES.nullish().catch(null)),
        zip: z.preprocess(
          v => {
            if (v === '') return null;
            return v;
          },
          z
            .string()
            .refine(val => /\d{5}/.test(val))
            .nullish(),
        ),
        country: z.literal(COUNTRY_ISO3166_ALPHA3.enum.USA),
      }),
      z.object({
        state: z.string().nullish(),
        zip: z.string().nullish(),
        country: COUNTRY_ISO3166_ALPHA3_NOT_US.nullish().catch(null),
      }),
    ]),
  );

const orgSimpleListingNoIdentifierSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  address: minimalPhysicalAddressForOrgSchema,
  contactInfo: orgContactSchema,
  website: z.string().nullish(),
  logo: z.string().nullish(),
  nteeCode: z.string(),
  nteeDescription: z.string(),
  featuredIndex: z.number(),
  // TODO: Make this consistent with the `orgClaimSchema`
  claimedType: z.enum(['WireClaim', 'WalletClaim']).nullish(),
  claimed: z.boolean(),
  deployments: z.array(orgDeploymentSchema),
});
const orgSimpleListingIdOnlySchema = orgSimpleListingNoIdentifierSchema.extend({
  id: z.undefined().nullish(),
  ein: einSchema,
});
const orgSimpleListingEinOnlySchema = orgSimpleListingNoIdentifierSchema.extend({
  id: uuidSchema,
  ein: z.undefined().nullish(),
});
const orgSimpleListingIdAndEinSchema = orgSimpleListingNoIdentifierSchema.extend({
  id: uuidSchema,
  ein: einSchema,
});
export const orgSimpleListingSchema = orgSimpleListingIdAndEinSchema
  .or(orgSimpleListingIdOnlySchema)
  .or(orgSimpleListingEinOnlySchema);
export type OrgSimpleListing = z.infer<typeof orgSimpleListingSchema>;

const orgListingNoIdentifierSchema = orgSimpleListingNoIdentifierSchema.extend({
  staffNotes: z.string().nullish(), // TODO: Ensure OrgListingDto returns this field
  /** USDC value */
  lifetimeContributionsUsdc: bigIntSchema,
  donationsReceived: z.number(),
  grantsReceived: z.number(),
});
const orgListingIdOnlySchema = orgListingNoIdentifierSchema.extend({
  id: z.undefined().nullish(),
  ein: einSchema,
});
const orgListingEinOnlySchema = orgListingNoIdentifierSchema.extend({
  id: uuidSchema,
  ein: z.undefined().nullish(),
});
const orgListingIdAndEinSchema = orgListingNoIdentifierSchema.extend({
  id: uuidSchema,
  ein: einSchema,
});
export const orgListingSchema = orgListingIdAndEinSchema.or(orgListingIdOnlySchema).or(orgListingEinOnlySchema);
export type OrgListing = z.infer<typeof orgListingSchema>;

export const orgSchema = orgListingNoIdentifierSchema.extend({
  id: uuidSchema,
  ein: einSchema.nullish(),
  compliant: z.boolean(),
  nonComplianceReasons: z.array(z.string()).nullish(),
  /** USDC value */
  usdcBalance: bigIntSchema,
  socials: orgSocialsSchema,
  financials: orgFinancialsSchema,
  partnerId: z.string().nullish(),
});
export type Org = z.infer<typeof orgSchema>;

export const subprojectListingSchema = z.object({
  id: uuidSchema,
  sponsorOrgId: uuidSchema,
  sponsorOrgEin: einSchema.nullish(),
  ein: einSchema.nullish(),
  name: z.string(),
  description: z.string().nullish(),
  website: z.string().nullish(),
  logo: z.string().nullish(),
  nteeCode: z.string(),
  nteeDescription: z.string(),
  featuredIndex: z.number(),
  lifetimeContributionsUsdc: bigIntSchema,
});
export type SubprojectListing = z.infer<typeof subprojectListingSchema>;

export const subprojectSchema = subprojectListingSchema.extend({
  address: minimalPhysicalAddressForOrgSchema,
  contactInfo: orgContactSchema,
  socials: orgSocialsSchema,
  deployments: z.array(orgDeploymentSchema),
  donationsReceived: z.number(),
  grantsReceived: z.number(),
});
export type Subproject = z.infer<typeof subprojectSchema>;

export const orgClaimSchema = z
  .object({
    id: uuidSchema,
    org: orgSchema.pick({
      id: true,
      ein: true,
      name: true,
      deployments: true,
    }),
    claimant: z.object({
      firstName: z.string().min(1),
      lastName: z.string().min(1),
      email: z.string().email(),
      address: physicalAddressSchema,
    }),
    claimStatus: z.enum(['Approved', 'Pending', 'Rejected', 'Revoked']),
  })
  .and(
    z.union([
      z.object({ claimType: z.literal('wallet'), managerAddress: addressSchema }),
      z.object({ claimType: z.literal('wire') }),
    ]),
  );
export type OrgClaim = z.infer<typeof orgClaimSchema>;

export const orgPayoutSchema = z.object({
  id: uuidSchema,
  orgId: uuidSchema,
  orgAdminWallet: addressSchema.nullish(),
  withdrawalAmount: z.number().nullish(),
  withdrawalDate: z.string(),
  txHash: addressSchema.nullish(),
  payoutId: uuidSchema,
  status: z.string(),
});
export type OrgPayout = z.infer<typeof orgPayoutSchema>;

export const isSubproject = (obj: unknown): obj is Subproject => subprojectSchema.safeParse(obj).success;
export const isSubprojectListing = (obj: unknown): obj is SubprojectListing =>
  subprojectListingSchema.safeParse(obj).success;
