import type { Chain } from 'viem/chains';
import { z } from 'zod';

import { addressSchema, evmTokenSchema, type Address, type AdminAccountName } from '@endaoment-frontend/types';

export const environmentSchema = z.enum(['local', 'dev', 'production', 'staging']);
export type Environment = z.infer<typeof environmentSchema>;

export type ConfigEntry = {
  environmentName: Environment;
  chains: [Chain, ...Array<Chain>];
  baseUrls: {
    app: string;
    marketing: string;
    developers: string;
    admin: string;
    api: string;
    docs: string;
    oauth: string;
  };
  endaoment: {
    accounts: Record<AdminAccountName, Array<Address>>;
  };
  socialLoginChainId: Chain['id'];
  circleAddress: Address;
  googleMapsApiKey: string;
  mapboxApiKey: string;
  zeroDev: {
    projectId: string;
    bundlerRpcUrl: string;
    paymasterRpcUrl: string;
  };
  privyAppId: string;
  heapAppId: string;
  identifiersSecret: string;
};

export type App = keyof Pick<ConfigEntry['baseUrls'], 'admin' | 'app' | 'developers' | 'marketing' | 'oauth'>;

export const defaultsEntrySchema = z.object({
  confirmations: z.object({
    donation: z.number(),
    wirePayout: z.number(),
    walletPayout: z.number(),
    portfolioTrade: z.number(),
    transfer: z.number(),
    setManager: z.number(),
    orgDeployment: z.number(),
    fundDeployment: z.number(),
  }),
  fees: z.object({
    donationBps: z.number(),
    transferBps: z.number(),
    stockDonationFeeBps: z.number(),
    tgbEntityStockDonationFeeBps: z.number(),
  }),
  network: z.object({
    defaultChainId: z.number(),
    supportedNetworkSettings: z.array(
      z.object({
        chainId: z.number(),
        baseToken: evmTokenSchema,
        nativeToken: evmTokenSchema,
        contracts: z.object({
          registry: addressSchema,
          orgFundFactory: addressSchema,
          batchOrgDeployer: addressSchema,
          transferDeployer: addressSchema,
          entityMulticall: addressSchema,
        }),
      }),
    ),
  }),
  stripePublishableKey: z.string(),
});
export type DefaultsEntry = z.infer<typeof defaultsEntrySchema>;
